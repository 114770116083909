import { API_ENDPOINT, RIDE_SERVICE_ENDPOINT } from "@/utils/api";
import axios from "axios";
import parseError from "@/store/helpers/parseError";
import moment from "moment";

const state = {
  bike: null,
  list: [],
  mileage: [],
  firstMileage: null,
  lastMileage: null,
  commands: [],
  owners: [],
  categories: [],
  suspensionTravels: [],
  experiences: [],
  wheelSizes: [],
  motorTypes: [],
};

const getters = {
  bike(state) {
    return state.bike;
  },
  list(state) {
    return state.list;
  },
  categories(state) {
    return state.categories;
  },
  suspensionTravels(state) {
    return state.suspensionTravels;
  },
  experiences(state) {
    return state.experiences;
  },
  wheelSizes(state) {
    return state.wheelSizes;
  },
  motorTypes() {
    return state.motorTypes;
  },
  owners(state) {
    return state.owners;
  },
  commands(state) {
    return state.commands;
  },
  mileage(state) {
    let groups = {};
    let mileage = state.mileage.map(item => {
      let oldCreatedAt = item.created_at;
      item.created_at = moment
        .unix(parseInt(item.created_at))
        .format("DD.MM.YYYY");
      item.year = item.created_at.split(".")[2];
      item.month = moment.unix(parseInt(oldCreatedAt)).format("MMMM");
      return item;
    });
    for (let item of mileage) {
      let key = `${item.month} ${item.year}`;
      // eslint-disable-next-line
      if (!groups.hasOwnProperty(key)) {
        groups[key] = [];
      }
      groups[key].push(item);
    }

    return groups;
  },
  firstMileage(state) {
    let firstMileage = state.firstMileage;
    if (!firstMileage) {
      return null;
    }
    let oldCreatedAt = firstMileage.created_at;
    firstMileage.created_at = moment
      .unix(parseInt(firstMileage.created_at))
      .format("DD.MM.YYYY");
    firstMileage.year = firstMileage.created_at.split(".")[2];
    firstMileage.month = moment.unix(parseInt(oldCreatedAt)).format("MMMM");
    return firstMileage;
  },
  lastMileage(state) {
    let lastMileage = state.lastMileage;
    if (!lastMileage) {
      return null;
    }
    let oldCreatedAt = lastMileage.created_at;
    lastMileage.created_at = moment
      .unix(parseInt(lastMileage.created_at))
      .format("DD.MM.YYYY");
    lastMileage.year = lastMileage.created_at.split(".")[2];
    lastMileage.month = moment.unix(parseInt(oldCreatedAt)).format("MMMM");
    return lastMileage;
  },
};
const actions = {
  manageMotorType(context, type) {
    return new Promise((resolve, reject) => {
      let id;
      if (type.id !== null) {
        id = `/${type.id}`;
      } else {
        id = "";
      }

      let url = `${API_ENDPOINT}v2/bikes/motor-types${id}`;

      axios
        .post(url, type)
        .then(response => {
          resolve(response.data.data);
        })
        .catch(err => {
          reject(parseError(err));
        });
    });
  },
  loadMotorTypesSingle(context, id) {
    return new Promise((resolve, reject) => {
      let url = `${API_ENDPOINT}v2/bikes/motor-types/${id}`;

      axios
        .get(url)
        .then(response => {
          resolve(response.data.data);
        })
        .catch(err => {
          reject(parseError(err));
        });
    });
  },
  manageWheelSize(context, size) {
    return new Promise((resolve, reject) => {
      let id;
      if (size.id !== null) {
        id = `/${size.id}`;
      } else {
        id = "";
      }

      let url = `${API_ENDPOINT}v2/bikes/wheel-sizes${id}`;

      axios
        .post(url, size)
        .then(response => {
          resolve(response.data.data);
        })
        .catch(err => {
          reject(parseError(err));
        });
    });
  },
  loadWheelSizesSingle(context, id) {
    return new Promise((resolve, reject) => {
      let url = `${API_ENDPOINT}v2/bikes/wheel-sizes/${id}`;

      axios
        .get(url)
        .then(response => {
          resolve(response.data.data);
        })
        .catch(err => {
          reject(parseError(err));
        });
    });
  },
  manageExperience(context, experience) {
    return new Promise((resolve, reject) => {
      let id;
      if (experience.id !== null) {
        id = `/${experience.id}`;
      } else {
        id = "";
      }

      let url = `${API_ENDPOINT}v2/bikes/experiences${id}`;

      axios
        .post(url, experience)
        .then(response => {
          resolve(response.data.data);
        })
        .catch(err => {
          reject(parseError(err));
        });
    });
  },
  loadExperiencesSingle(context, id) {
    return new Promise((resolve, reject) => {
      let url = `${API_ENDPOINT}v2/bikes/experiences/${id}`;

      axios
        .get(url)
        .then(response => {
          resolve(response.data.data);
        })
        .catch(err => {
          reject(parseError(err));
        });
    });
  },
  manageSuspensionTravel(context, travel) {
    return new Promise((resolve, reject) => {
      let id;
      if (travel.id !== null) {
        id = `/${travel.id}`;
      } else {
        id = "";
      }

      let url = `${API_ENDPOINT}v2/bikes/suspension-travels${id}`;

      axios
        .post(url, travel)
        .then(response => {
          resolve(response.data.data);
        })
        .catch(err => {
          reject(parseError(err));
        });
    });
  },
  loadSuspensionTravelsSingle(context, id) {
    return new Promise((resolve, reject) => {
      let url = `${API_ENDPOINT}v2/bikes/suspension-travels/${id}`;

      axios
        .get(url)
        .then(response => {
          resolve(response.data.data);
        })
        .catch(err => {
          reject(parseError(err));
        });
    });
  },
  manageBikeCategory(context, category) {
    return new Promise((resolve, reject) => {
      let id;
      if (category.id !== null) {
        id = `/${category.id}`;
      } else {
        id = "";
      }

      let url = `${API_ENDPOINT}v2/bikes/categories${id}`;

      axios
        .post(url, category)
        .then(response => {
          resolve(response.data.data);
        })
        .catch(err => {
          reject(parseError(err));
        });
    });
  },
  loadCategoriesSingle(context, id) {
    return new Promise((resolve, reject) => {
      let url = `${API_ENDPOINT}v2/bikes/categories/${id}`;

      axios
        .get(url)
        .then(response => {
          resolve(response.data.data);
        })
        .catch(err => {
          reject(parseError(err));
        });
    });
  },
  loadMotorTypes({ commit }) {
    return new Promise((resolve, reject) => {
      let url = `${API_ENDPOINT}v2/bikes/motor-types`;

      axios
        .get(url)
        .then(response => {
          commit("setMotorTypes", response.data.data);
          resolve(response);
        })
        .catch(err => {
          reject(parseError(err));
        });
    });
  },
  loadWheelSizes({ commit }) {
    return new Promise((resolve, reject) => {
      let url = `${API_ENDPOINT}v2/bikes/wheel-sizes`;

      axios
        .get(url)
        .then(response => {
          commit("setWheelSizes", response.data.data);
          resolve(response);
        })
        .catch(err => {
          reject(parseError(err));
        });
    });
  },
  loadExperiences({ commit }) {
    return new Promise((resolve, reject) => {
      let url = `${API_ENDPOINT}v2/bikes/experiences`;

      axios
        .get(url)
        .then(response => {
          commit("setExperiences", response.data.data);
          resolve(response);
        })
        .catch(err => {
          reject(parseError(err));
        });
    });
  },
  loadTravels({ commit }) {
    return new Promise((resolve, reject) => {
      let url = `${API_ENDPOINT}v2/bikes/suspension-travels`;

      axios
        .get(url)
        .then(response => {
          commit("setSuspensionTravels", response.data.data);
          resolve(response);
        })
        .catch(err => {
          reject(parseError(err));
        });
    });
  },
  loadCategories({ commit }) {
    return new Promise((resolve, reject) => {
      let url = `${API_ENDPOINT}v2/bikes/categories`;

      axios
        .get(url)
        .then(response => {
          commit("setCategories", response.data.data);
          resolve(response);
        })
        .catch(err => {
          reject(parseError(err));
        });
    });
  },
  loadCommands({ commit }, bikeID) {
    return new Promise((resolve, reject) => {
      let url = `${API_ENDPOINT}v2/bikes/${bikeID}/commands/crm`;

      axios
        .get(url)
        .then(response => {
          commit("setCommands", response.data);
          resolve(response);
        })
        .catch(err => {
          reject(parseError(err));
        });
    });
  },
  storeCommand(context, data) {
    return new Promise((resolve, reject) => {
      let bikeID = data.bikeID;
      let url = `${API_ENDPOINT}v2/bikes/${bikeID}/commands`;

      delete data.bikeID;

      axios
        .post(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(parseError(err));
        });
    });
  },
  exportSerials(context, bikeID) {
    return new Promise((resolve, reject) => {
      let url = `${API_ENDPOINT}v2/bikes/${bikeID}/serials/export`;

      axios
        .get(url)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(parseError(err));
        });
    });
  },
  load({ commit }, data) {
    return new Promise((resolve, reject) => {
      let url = `${API_ENDPOINT}v2/bikes/${data.bikeID}`;
      if (data.withFullBuild) {
        url += "?with-full-build";
      }
      axios
        .get(url)
        .then(result => {
          commit("setBike", result.data);
          resolve(result.data);
        })
        .catch(error => {
          commit("setBike", null);
          error = parseError(error);
          reject(typeof error === "string" ? new Error(error) : error);
        });
    });
  },
  loadOwners({ commit }, data) {
    return new Promise((resolve, reject) => {
      let url = `${API_ENDPOINT}v2/bikes/${data.bikeID}/owners`;
      axios
        .get(url)
        .then(result => {
          commit("setOwners", result.data);
          resolve(result.data);
        })
        .catch(error => {
          commit("setOwners", []);
          error = parseError(error);
          reject(typeof error === "string" ? new Error(error) : error);
        });
    });
  },
  loadList({ state }, data) {
    return new Promise((resolve, reject) => {
      let url = `${API_ENDPOINT}v2/bikes/crm?page=${data.page}`;
      if (data.filters) {
        let filters = [];
        for (let filterKey of Object.keys(data.filters)) {
          if (
            data.filters[filterKey] !== "" &&
            data.filters[filterKey] !== null
          ) {
            let selectKeys = ["model", "build", "size", "color", "status", "testing"];
            if (selectKeys.includes(filterKey)) {
              filters.push(`${filterKey}Filter=${data.filters[filterKey].id}`);
            } else {
              filters.push(`${filterKey}Filter=${data.filters[filterKey]}`);
            }
          }
          if (filters.length > 0) {
            url += `&${filters.join("&")}`;
          }
        }
      }
      axios
        .get(url)
        .then(result => {
          state.list = [...result.data.data];
          // commit("setList", [...result.data.data]);
          resolve(result.data);
        })
        .catch(error => {
          // commit("setList", []);
          state.list = [];
          reject(error);
        });
    });
  },
  save({ getters }, bike_id) {
    return new Promise((resolve, reject) => {
      const url = `${API_ENDPOINT}v2/bikes/${bike_id}`;
      axios
        .post(url, getters["bike"])
        .then(result => {
          resolve(result.data);
        })
        .catch(error => {
          if (
            error.response &&
            error.response.status &&
            error.response.status === 403
          ) {
            reject(
              new Error("Only admin can edit main information of the bike"),
            );
          } else {
            error = parseError(error);
            reject(typeof error === "string" ? new Error(error) : error);
          }
        });
    });
  },
  updateSerial(context, data) {
    return new Promise((resolve, reject) => {
      const url = `${API_ENDPOINT}v2/bikes/${data.bike_id}/serial`;
      delete data.bike_id;
      axios
        .post(url, data)
        .then(result => {
          resolve(result.data);
        })
        .catch(error => {
          reject(parseError(error));
        });
    });
  },
  saveEdited(context, data) {
    return new Promise((resolve, reject) => {
      const url = `${API_ENDPOINT}v2/bikes/${data.bike_id}/serial/update`;
      delete data.bike_id;
      axios
        .post(url, data)
        .then(result => {
          resolve(result.data);
        })
        .catch(error => {
          reject(parseError(error));
        });
    });
  },
  deleteSerial(context, data) {
    return new Promise((resolve, reject) => {
      const url = `${API_ENDPOINT}v2/bikes/${data.bike_id}/serial/delete`;
      delete data.bike_id;
      axios
        .post(url, data)
        .then(result => {
          resolve(result.data);
        })
        .catch(error => {
          reject(parseError(error));
        });
    });
  },
  loadMileage({ commit }, data) {
    return new Promise((resolve, reject) => {
      const url = `${RIDE_SERVICE_ENDPOINT}mileage/crm`;
      axios
        .post(url, { bike_id: data.bike_id })
        .then(result => {
          commit("setMileAge", result.data.data.mileage);
          commit("setFirstMileAge", result.data.data.first);
          commit("setLastMileAge", result.data.data.last);
          resolve(result.data);
        })
        .catch(error => {
          reject(parseError(error));
        });
    });
  },
};
const mutations = {
  setMotorTypes(state, types) {
    state.motorTypes = types;
  },
  setWheelSizes(state, sizes) {
    state.wheelSizes = sizes;
  },
  setExperiences(state, experiences) {
    state.experiences = experiences;
  },
  setSuspensionTravels(state, travels) {
    state.suspensionTravels = travels;
  },
  setCategories(state, categories) {
    state.categories = categories;
  },
  setCommands(state, commands) {
    state.commands = commands;
  },
  setBike(state, bike) {
    state.bike = bike;
  },
  setOwners(state, owners) {
    state.owners = owners;
  },
  setList(value) {
    state.list = value;
  },
  setSerialNumber(state, number) {
    state.bike.serial_number = number;
  },
  setFrameNumber(state, frame) {
    state.bike.frame_number = frame;
  },
  setManufacturingDate(state, date) {
    state.bike.manufacturing_date = date;
  },
  setSimCardNumber(state, number) {
    state.bike.sim_card_number = number;
  },
  setStatus(state, value) {
    state.bike.status = value.code;
  },
  setGPSAntennaType(state, value) {
    state.bike.gps_antenna_type = value.code;
  },
  setBrakeType(state, value) {
    state.bike.brake_type = value.code;
  },
  setSpeedLimit(state, value) {
    state.bike.speed_limit = value.code;
  },
  setMainStatus(state, value) {
    state.bike.main_status = value.code;
  },
  setOwner(state, value) {
    state.bike.owner = value;
  },
  setMileAge(state, value) {
    state.mileage = value;
  },
  setFirstMileAge(state, value) {
    state.firstMileage = value;
  },
  setLastMileAge(state, value) {
    state.lastMileage = value;
  },
  setTitle(state, value) {
    state.bike.title = value;
  },
  setModeEditForSerial(state, data) {
    state.bike.serials = state.bike.serials.map(item => {
      if (item.id === data.spec.id) {
        item.old_serials = item.old_serials.map(serial => {
          if (serial.id === data.serial.id) {
            serial.modeEdit = data.edit;
            if (!data.edit) {
              delete serial.tempSerial;
            } else {
              serial.tempSerial = serial.serial;
            }
          }
          return serial;
        });
      }
      return item;
    });
  },
  editOldSerial(state, data) {
    state.bike.serials = state.bike.serials.map(item => {
      if (item.id === data.spec.id) {
        item.old_serials = item.old_serials.map(serial => {
          if (serial.id === data.serial.id) {
            serial.tempSerial = data.value;
          }
          return serial;
        });
      }
      return item;
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
