import Vue from "vue";
import Router from "vue-router";
import CRMLoginScreen from "@/components/CRMLoginScreen";
import CRMManageCustomers from "@/components/CRMManageCustomers";
import CRMManageCustomersB2b from "@/components/CRMManageCustomersB2b";
import store from "@/store/store";
import CRMCustomerList from "@/components/CRMCustomerList";
import CRMCustomerRoutingPage from "@/components/CRMCustomerRoutingPage";
import CRMDashBoard from "@/components/CRMDashBoard";
import CRMProducts from "@/components/CRMProducts";
import CRMOrders from "@/components/CRMOrders";
import CRMForgotPasswordScreen from "@/components/CRMForgotPasswordScreen";
import CRMPosts from "@/components/CRMPosts";
import CRMPostCreate from "@/components/CRMPostCreate";
import CRMPostPage from "@/components/CRMPostPage";
import CRMProductsCreate from "@/components/CRMProductsCreate";
import CRMProductPartCreate from "@/components/CRMProductPartCreate";
import CRMPartCategoriesCreate from "@/components/CRMPartCategoriesCreate";
import CRMPartCategories from "@/components/CRMPartCategories";
import CRMPartBrandCreate from "@/components/CRMPartBrandCreate";
import CRMPartBrands from "@/components/CRMPartBrands";
import CRMPartCategoriesShow from "@/components/CRMPartCategoriesShow";
import CRMPartBrandShow from "@/components/CRMPartBrandShow";
import CRMImportComponents from "@/components/CRMImportComponents";
import CRMImportCountComponents from "@/components/CRMImportCountComponents";
import CRMPartShow from "@/components/CRMPartShow";
import CRMProductsShow from "@/components/CRMProductsShow";
import ManageBuildColor from "@/components/ManageBuildColor";
import CRMProductModelCreate from "@/components/CRMProductModelCreate";
import CRMProductModels from "@/components/CRMProductModels";
import CRMProductModelShow from "@/components/CRMProductModelShow";
import CRMAdmin from "@/components/CRMAdmin";
import CRMOrder from "@/components/CRMOrder";
import CRMPageCreate from "@/components/CRMPageCreate";
import CRMPageList from "@/components/CRMPageList";
import CRMPageShow from "@/components/CRMPageShow";
import CRMSupportEntryCreate from "@/components/CRMSupportEntryCreate";
import CRMSupportEntryPage from "@/components/CRMSupportEntryPage";
import CRMSupports from "@/components/CRMSupports";
import CRMSupportCategoriesCreate from "@/components/CRMSupportCategoriesCreate";
import CRMSupportCategoriesPage from "@/components/CRMSupportCategoriesPage";
import CRMSettingsUpdate from "@/components/CRMSettingsUpdate";
import CRMMediaLibraryList from "@/components/CRMMediaLibraryList";
import CRMMediaLibrarySettings from "@/components/CRMMediaLibrarySettings";
import CRMMediaLibraryContent from "@/components/CRMMediaLibraryContent";
import CRMRoleCreate from "@/components/CRMRoleCreate";
import CRMRoleEdit from "@/components/CRMRoleEdit";
import CrmRoles from "@/components/CrmRoles";
import CRMVacancyDepartmentCreate from "@/components/CRMVacancyDepartmentCreate";
import CRMVacancyDepartmentList from "@/components/CRMVacancyDepartmentList";
import CRMVacancyDepartmentEdit from "@/components/CRMVacancyDepartmentEdit";
import CRMVacancyCreate from "@/components/CRMVacancyCreate";
import CRMVacancyList from "@/components/CRMVacancyList";
import CRMVacancyShow from "@/components/CRMVacancyShow";
import CRMCVList from "@/components/CRMCVList";
import CRMCVShow from "@/components/CRMCVShow";
import CRMMailsList from "@/components/CRMMailsList";
import axios from "axios";
import { API_ENDPOINT } from "@/utils/api";
import CRMOrderCreate from "@/components/CRMOrderCreate";
import CRMEditBuild from "@/components/CRMEditBuild";
import UserBike from "@/components/UserBike";
import CRMNewProducts from "@/components/CRMNewProducts";
import CRMBuildTitles from "@/components/CRMBuildTitles";
import BuildColors from "@/components/BuildColors";
import PublicUploader from "@/components/PublicUploader";
import UserBikeList from "@/components/UserBikesList";
import ErrorCodes from "@/components/ErrorCodes";
import DealersList from "@/components/dealers/list";
import DealersForm from "@/components/dealers/form";
import CRMBikeCategories from "@/components/CRMBikeCategories.vue";
import CRMBuildSuspensionTravels from "@/components/CRMBuildSuspensionTravels.vue";
import CRMBuildExperiences from "@/components/CRMBuildExperiences.vue";
import CRMBuildWheelSizes from "@/components/CRMBuildWheelSizes.vue";
import CRMBuildMotorTypes from "@/components/CRMBuildMotorTypes.vue";
import ManageBikeCategory from "@/components/ManageBikeCategory.vue";
import ManageBuildSuspensionTravels from "@/components/ManageBuildSuspensionTravels.vue";
import ManageBikeExperience from "@/components/ManageBikeExperience.vue";
import ManageBikeWheelSize from "@/components/ManageBikeWheelSize.vue";
import ManageBikeMotorType from "@/components/ManageBikeMotorType.vue";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "CRMDashBoard",
      component: CRMDashBoard,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/admin",
      name: "CRMAdmin",
      component: CRMAdmin,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/products",
      name: "CRMProducts",
      component: CRMProducts,
      meta: {
        requiresAuth: true,
        premission_id: 1,
      },
    },
    {
      path: "/products/create",
      name: "CRMProductsCreate",
      component: CRMProductsCreate,
      meta: {
        requiresAuth: true,
        premission_id: 1,
        isCreate: true,
      },
    },
    {
      path: "/products/parts/create",
      name: "CRMProductPartCreate",
      component: CRMProductPartCreate,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/products/parts/categories/create",
      name: "CRMPartCategoriesCreate",
      component: CRMPartCategoriesCreate,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/products/parts/categories",
      name: "CRMPartCategories",
      component: CRMPartCategories,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/products/parts/categories/:id",
      name: "CRMPartCategoriesShow",
      component: CRMPartCategoriesShow,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/products/parts/brands/create",
      name: "CRMPartBrandCreate",
      component: CRMPartBrandCreate,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/products/parts/brands",
      name: "CRMPartBrands",
      component: CRMPartBrands,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/products/parts/brands/:id",
      name: "CRMPartBrandShow",
      component: CRMPartBrandShow,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/products/parts/:id",
      name: "CRMPartShow",
      component: CRMPartShow,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/colors",
      name: "BuildColors",
      component: BuildColors,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/filter-categories",
      name: "CRMBikeCategories",
      component: CRMBikeCategories,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/filter-categories/create",
      name: "ManageBikeCategory",
      component: ManageBikeCategory,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/filter-categories/:id",
      name: "ManageBikeCategoryEdit",
      component: ManageBikeCategory,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/suspension-travels",
      name: "CRMBuildSuspensionTravels",
      component: CRMBuildSuspensionTravels,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/suspension-travels/create",
      name: "ManageBuildSuspensionTravels",
      component: ManageBuildSuspensionTravels,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/suspension-travels/:id",
      name: "ManageBuildSuspensionTravelsEdit",
      component: ManageBuildSuspensionTravels,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/experiences",
      name: "CRMBuildExperiences",
      component: CRMBuildExperiences,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/experiences/create",
      name: "ManageBikeExperience",
      component: ManageBikeExperience,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/experiences/:id",
      name: "ManageBikeExperienceEdit",
      component: ManageBikeExperience,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/wheel-sizes",
      name: "CRMBuildWheelSizes",
      component: CRMBuildWheelSizes,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/wheel-sizes/create",
      name: "ManageBikeWheelSize",
      component: ManageBikeWheelSize,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/wheel-sizes/:id",
      name: "ManageBikeWheelSizeEdit",
      component: ManageBikeWheelSize,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/motor-types",
      name: "CRMBuildMotorTypes",
      component: CRMBuildMotorTypes,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/motor-types/create",
      name: "ManageBikeMotorType",
      component: ManageBikeMotorType,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/motor-types/:id",
      name: "ManageBikeMotorTypeEdit",
      component: ManageBikeMotorType,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/colors/:id",
      name: "ManageBuildColorSingle",
      component: ManageBuildColor,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/colors/create",
      name: "ManageBuildColor",
      component: ManageBuildColor,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/public-uploader",
      name: "PublicUploader",
      component: PublicUploader,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/products/models",
      name: "CRMProductModels",
      component: CRMProductModels,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/products/models/create",
      name: "CRMProductModelCreate",
      component: CRMProductModelCreate,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/products/models/:id",
      name: "CRMProductModelShow",
      component: CRMProductModelShow,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/products/:id",
      name: "CRMProductsShow",
      component: CRMProductsShow,
      meta: {
        requiresAuth: true,
        premission_id: 1,
      },
    },
    {
      path: "/orders",
      name: "CRMOrders",
      component: CRMOrders,
      meta: {
        requiresAuth: true,
        premission_id: 3,
      },
    },
    {
      path: "/orders/create",
      name: "CRMOrderCreate",
      component: CRMOrderCreate,
      meta: {
        requiresAuth: true,
        premission_id: 3,
      },
    },
    {
      path: "/orders/:id",
      name: "CRMOrder",
      component: CRMOrder,
      meta: {
        requiresAuth: true,
        premission_id: 3,
      },
    },
    {
      path: "/posts/create",
      name: "CRMPostCreate",
      component: CRMPostCreate,
      meta: {
        requiresAuth: true,
        premission_id: 4,
        isCreate: true,
      },
    },
    {
      path: "/supports/create",
      name: "CRMSupportEntryCreate",
      component: CRMSupportEntryCreate,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/pages/create",
      name: "CRMPageCreate",
      component: CRMPageCreate,
      meta: {
        requiresAuth: true,
        premission_id: 10,
      },
    },
    {
      path: "/pages",
      name: "CRMPageList",
      component: CRMPageList,
      meta: {
        requiresAuth: true,
        premission_id: 10,
      },
    },
    {
      path: "/pages/:id",
      name: "CRMPageShow",
      component: CRMPageShow,
      meta: {
        requiresAuth: true,
        premission_id: 10,
      },
    },
    {
      path: "/import/parts",
      name: "CRMImportComponents",
      component: CRMImportComponents,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/import/parts/count",
      name: "CRMImportCountComponents",
      component: CRMImportCountComponents,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/customers/create",
      name: "CRMManageCustomers",
      component: CRMManageCustomers,
      meta: {
        requiresAuth: true,
        premission_id: 2,
        isCreate: true,
      },
    },
    {
      path: "/customers/create_b2b",
      name: "CRMManageCustomersB2b",
      component: CRMManageCustomersB2b,
      meta: {
        requiresAuth: true,
        premission_id: 2,
        isCreate: true,
      },
    },
    {
      path: "/customers",
      name: "CRMCustomerList",
      component: CRMCustomerList,
      meta: {
        requiresAuth: true,
        premission_id: 2,
      },
    },
    {
      path: "/customers/:id",
      name: "CRMCustomerPage",
      component: CRMCustomerRoutingPage,
      meta: {
        requiresAuth: true,
        premission_id: 2,
      },
    },
    {
      path: "/posts",
      name: "CRMPosts",
      component: CRMPosts,
      meta: {
        requiresAuth: true,
        premission_id: 4,
      },
    },
    {
      path: "/posts/:id",
      name: "CRMPostPage",
      component: CRMPostPage,
      meta: {
        requiresAuth: true,
        premission_id: 4,
      },
    },
    {
      path: "/supports",
      name: "CRMSupports",
      component: CRMSupports,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/supports/categories/:id",
      name: "CRMSupportCategoriesPage",
      component: CRMSupportCategoriesPage,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/supports/categories",
      name: "CRMSupportCategoriesCreate",
      component: CRMSupportCategoriesCreate,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/support/:id",
      name: "CRMSupportEntryPage",
      component: CRMSupportEntryPage,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/settings",
      name: "CRMSettingsUpdate",
      component: CRMSettingsUpdate,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/media",
      name: "CRMMediaLibraryList",
      component: CRMMediaLibraryList,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/media/settings",
      name: "CRMMediaLibrarySettings",
      component: CRMMediaLibrarySettings,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/media/create",
      name: "CRMMediaLibraryCreate",
      component: CRMMediaLibraryContent,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/media/:id",
      name: "CRMMediaLibraryContent",
      component: CRMMediaLibraryContent,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/login",
      name: "CRMLoginScreen",
      component: CRMLoginScreen,
    },
    {
      path: "/remember",
      name: "CRMForgotPasswordScreen",
      component: CRMForgotPasswordScreen,
    },
    {
      path: "/roles",
      name: "CrmRoles",
      component: CrmRoles,
      meta: {
        requiresAuth: true,
        roles: true,
      },
    },
    {
      path: "/roles/create",
      name: "CRMRoleCreate",
      component: CRMRoleCreate,
      meta: {
        requiresAuth: true,
        roles: true,
      },
    },
    {
      path: "/roles/:id",
      name: "CRMRoleEdit",
      component: CRMRoleEdit,
      meta: {
        requiresAuth: true,
        roles: true,
      },
    },
    {
      path: "/departments/create",
      name: "CRMVacancyDepartmentCreate",
      component: CRMVacancyDepartmentCreate,
      meta: {
        requiresAuth: true,
        premission_id: 9,
      },
    },
    {
      path: "/departments",
      name: "CRMVacancyDepartmentList",
      component: CRMVacancyDepartmentList,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/departments/:id",
      name: "CRMVacancyDepartmentEdit",
      component: CRMVacancyDepartmentEdit,
      meta: {
        requiresAuth: true,
        roles: true,
      },
    },
    {
      path: "/vacancies/create",
      name: "CRMVacancyCreate",
      component: CRMVacancyCreate,
      meta: {
        requiresAuth: true,
        premission_id: 9,
      },
    },
    {
      path: "/vacancies/:id",
      name: "CRMVacancyShow",
      component: CRMVacancyShow,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/vacancies",
      name: "CRMVacancyList",
      component: CRMVacancyList,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/cv",
      name: "CRMCVList",
      component: CRMCVList,
      meta: {
        requiresAuth: true,
        premission_id: 9,
      },
    },
    {
      path: "/cv/:id",
      name: "CRMCVShow",
      component: CRMCVShow,
      meta: {
        requiresAuth: true,
        premission_id: 9,
      },
    },
    {
      path: "/mails",
      name: "CRMMailsList",
      component: CRMMailsList,
      meta: {
        requiresAuth: true,
        premission_id: 11,
      },
    },
    {
      path: "/builds/create",
      name: "CRMEditBuild",
      component: CRMEditBuild,
      meta: {
        requiresAuth: true,
        premission_id: 11,
      },
    },
    {
      path: "/bikes/:id",
      name: "UserBike",
      component: UserBike,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/builds/:id",
      name: "CRMEditBuildShow",
      component: CRMEditBuild,
      meta: {
        requiresAuth: true,
        premission_id: 11,
      },
    },
    {
      path: "/builds",
      name: "CRMNewProducts",
      component: CRMNewProducts,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/build-titles",
      name: "CRMBuildTitles",
      component: CRMBuildTitles,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/bikes",
      name: "UserBikesList",
      component: UserBikeList,
    },
    {
      path: "/error-codes",
      name: "ErrorCodes",
      component: ErrorCodes,
    },
    {
      path: "/dealers",
      name: "DealersList",
      component: DealersList,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/dealers/create",
      name: "DealersCreate",
      component: DealersForm,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/dealers/update/:id",
      name: "DealersUpdate",
      component: DealersForm,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/demo-and-service-partners",
      name: "DemoAndServicePartnersList",
      component: DealersList,
      meta: {
        requiresAuth: true,
        partners: true,
      },
    },
    {
      path: "/demo-and-service-partners/create",
      name: "DemoAndServicePartnersCreate",
      component: DealersForm,
      meta: {
        requiresAuth: true,
        partners: true,
      },
    },
    {
      path: "/demo-and-service-partners/update/:id",
      name: "DemoAndServicePartnersUpdate",
      component: DealersForm,
      meta: {
        requiresAuth: true,
        partners: true,
      },
    },
    {
      path: "/main-page",
      name: "MainPage",
      component: () => import("@/components/MainPage"),
    },
    {
      path: "/test-page",
      name: "TestPage",
      component: () => import("@/components/Test"),
    },
    {
      path: "/settings/:model",
      name: "Settings",
      component: () => import("@/components/Settings"),
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem("token") !== null) {
      let token = localStorage.getItem("token");
      let url = API_ENDPOINT + "user";
      axios
        .get(url, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        })
        .then(response => {
          if (store.getters.isLoggedIn) {
            localStorage.setItem("user", JSON.stringify(response.data));
            let user = response.data;
            for (let premission of user.user_type.premissions) {
              if (
                to.matched.some(
                  record =>
                    record.meta.premission_id === premission.premisson_id,
                )
              ) {
                if (to.matched.some(record => record.meta.isCreate)) {
                  if (premission.edit === 1) {
                    next();
                    break;
                  }
                  next("/");
                }
                if (premission.view === 1) {
                  next();
                  break;
                }
                next("/");
              }
            }
            if (to.matched.some(record => record.meta.roles)) {
              if (user.user_type_id !== 1) {
                next("/");
              }
            }
            next();
          }
        })
        .catch(err => {
          if (
            err.response &&
            err.response.status &&
            err.response.status !== 200
          ) {
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            next("/login");
          }
        });
    } else {
      next("/login");
    }
  } else {
    next();
  }
});

export default router;
